import bootstrapper from '../../bootstrapper';
import amplitude from './amplitude';
import initAmplitude from './amplitude/initializer';
import ghostInspector from './ghostInspector';
import initGhostInspector from './ghostInspector/initializer';
import logger from './logger';
import { emeritusPixelMiddleware, initEmeritusPixel } from './emeritusPixel';

let middlewares;

switch (bootstrapper.appEnv) {
  case 'test': middlewares = [logger, emeritusPixelMiddleware]; break;
  case 'development': middlewares = [logger, emeritusPixelMiddleware]; break;
  case 'review_app': middlewares = [logger, amplitude, ghostInspector, emeritusPixelMiddleware]; break;
  case 'staging': middlewares = [logger, amplitude, ghostInspector, emeritusPixelMiddleware]; break;
  case 'production': middlewares = [amplitude, emeritusPixelMiddleware]; break;
  default: middlewares = []; break;
}

export default {
  list: middlewares,
  initialize: () => {
    initAmplitude();
    initGhostInspector();
    initEmeritusPixel(true);
  },
};
